<template>
  <div>
    <div class="card">
      <div v-if="paymentOptionsLoaded" class="flex w-full flex-col pb-2">
        <div class="flex">
          <div class="w-4/6"><h3 class="text-1xl leading-lg">{{ offer.issuesForClean }}</h3></div>
          <div class="w-2/6 text-right"><h3 class="text-1xl leading-lg">{{ offer.priceDisplay }}</h3></div>
        </div>

        <div class="flex text-gray-600 border-b border-bp-lightgray pb-3">
          <div class="w-3/6 text-sm leading-xs text-base md:text-base md:leading-sm">{{ t('CheckoutV2', 'normal_price') }}</div>
          <div class="w-3/6 text-sm leading-xs text-right md:text-base md:leading-sm">{{ offer.normalPriceDisplay }}</div>
        </div>

        <div class="pt-4" v-if="shouldShowRecurringPaymentMethod">
          <h3 class="text-xl leading-medium mb-4 font-black content-font">{{ t('CheckoutV2', 'payment_method') }}:</h3>

          <p v-if="activePaymentMethod.paymentTypeApi === 'mobilePayment'"
             class="text-xl font-medium mb-4 pt-4">{{ t('CheckoutV2', 'recurringPaymentMobilePay')}}</p>
          <p v-else-if="activePaymentMethod.paymentTypeApi === 'cardPayment'"
             class="text-xl font-medium mb-4 pt-4">{{ t('CheckoutV2', 'recurringPaymentCreditCard')}}</p>

          <div class="border-gray-border border p-4 rounded-lg mb-4" :class="{'border-bp-turquoise': paymentType === 'recurring'}">
            <div class="flex">
              <input type="radio" value="recurring" id="recurring-payment" v-model="paymentType" class="hidden">
              <input type="radio" v-bind:id="paymentOptions[0].id" v-bind:value="paymentOptions[0].value" v-model="selectedPaymentOption" class="hidden">

              <label class="flex flex-1">
                <div class="flex">
                  <img :src="activePaymentMethod.paymentMethodLogoUrl" class="max-h-6 mr-4" :alt="activePaymentMethod.paymentType">
                </div>
                <span class="text-xl leading-none font-medium">
                  {{ activePaymentMethod.paymentType }}
                  <span v-if="hasCardNumber">({{ activePaymentMethod.cardNumber }})</span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="pt-4" v-else>
          <h3 class="text-xl leading-medium mb-4 font-bold content-font">{{ t('CheckoutV2', 'choose_payment') }}:</h3>

          <div class="tabs" v-if="hasGiroPayment">
            <ul>
              <li :class="{active: paymentType === 'card'}">
                <input type="radio" value="card" id="card-payment" v-model="paymentType" class="mr-4">
                <label for="card-payment" class="uppercase">
                  {{ t('CheckoutV2', 'card') }}
                </label>
              </li>
              <li :class="{active: paymentType === 'invoice'}">
                <input type="radio" value="invoice" id="invoice-payment" v-model="paymentType" class="mr-4">
                <label for="invoice-payment" class="uppercase">
                  {{ t('CheckoutV2', 'invoice') }}
                </label>
              </li>
            </ul>
          </div>

          <div v-for="paymentOption in paymentOptions" v-bind:key="paymentOption.id">
            <div
              class="flex border-gray-border border p-4 rounded-lg mb-4"
              :class="{
                'border-red-600': !selectedPaymentOption && shouldValidate,
                'border-bp-turquoise': selectedPaymentOption === paymentOption.value,
              }"
              v-if="paymentType === 'invoice' && paymentOption.isGiro || paymentType === 'card' && !paymentOption.isGiro"
            >
              <input type="radio" v-bind:id="paymentOption.id" v-bind:value="paymentOption.value" class="mr-4" v-model="selectedPaymentOption" />
              <label v-bind:for="paymentOption.id" class="flex items-center flex-1" :class="{'justify-between': paymentType !== 'invoice'}">
                <span class="text-base md:text-xl leading-none">
                  {{ paymentOption.label }}
                </span>

                <div class="flex w-6/12 items-center justify-end" v-if="paymentType !== 'invoice'">
                  <img
                    v-bind:key="image"
                    v-for="image in paymentOption.logos"
                    class="mr-1"
                    style="max-height: 1rem; width: auto; height: auto;"
                    v-bind:src="image"
                  />
                </div>
              </label>
            </div>
          </div>

          <div class="w-full cards mb-3" v-if="paymentType === 'card'">
            <div class="w-full flex" v-if="cardsTermsCheckbox">
              <input
                type="checkbox"
                id="terms_card"
                v-model="acceptedCardTerms"
                class="border-gray-border border rounded-sm mr-2"
              />
              <label for="terms_card" class="terms_card text-sm md:text-base font-medium">
                <span v-html="t('CheckoutV2', 'terms_card')"></span>
                <span class="cursor-pointer">
                  {{ ' ' }}
                  <a class="underline text-bp-blue font-bold" @click.stop.prevent="openCardTerms">
                    {{ t('CheckoutV2', 'terms_card_read_more') }}
                  </a>
                </span>
              </label>
            </div>
          </div>
          <div class="w-full cards mb-3" v-else-if="paymentType === 'invoice'">
            <div class="w-full flex" v-if="invoiceTermsCheckbox">
              <input
                type="checkbox"
                id="terms_invoice"
                v-model="acceptedInvoiceTerms"
                class="border-gray-border border rounded-sm mr-2"
              />
              <label for="terms_invoice" class="terms_card text-sm md:text-base font-medium">
                <span v-html="t('CheckoutV2', 'terms_invoice')"></span>
                <span class="cursor-pointer">
                  {{ ' ' }}
                  <a class="underline text-bp-blue font-bold" @click.stop.prevent="openInvoiceTerms">
                    {{ t('CheckoutV2', 'terms_card_read_more') }}
                  </a>
                </span>
              </label>
            </div>
          </div>
        </div>

        <small class="text-bp-error my-2 text-center leading-4" v-if="showErrorMessage">
          <span v-if="!selectedPaymentOption.length">{{t('GeneralV2', 'selectPaymentOption') }}</span>
          <span v-else-if="genericError">{{t('GeneralV2', 'genericError') }}</span>
          <span v-else-if="paymentType === 'card'">{{t('GeneralV2', 'acceptTerms') }}</span>
          <span v-else-if="paymentType === 'invoice'">{{t('GeneralV2', 'acceptTerms') }}</span>
        </small>

        <div v-if="campaign.porto.length > 0">
          <p class="mb-2 text-center text-xs text-gray-text">
            {{ t('CheckoutV2', 'price') }}: {{ offer.priceDisplay }} +
            {{ t('CheckoutV2', 'porto') }} {{ ' ' }} {{ campaign.porto }}
          </p>
        </div>
      </div>

      <LoadingView class="pt-12 pb-12" v-else></LoadingView>
    </div>

    <div class="flex flex-wrap items-start md:flex-nowrap text-lg mt-6">
      <div class="w-full md:w-3/6 order-2 md:order-1">
        <PreviousExtension
          :id="this.$options.name + '-prev'"
          :styling="'btn btn--secondary-cta w-full md:flex-1 md:mr-2'"
          :button-text="t('GeneralV2', 'go_back')">
        </PreviousExtension>
      </div>

      <div class="w-full md:w-3/6 order-1 md:order-2 mb-4 md:mb-0">
        <MyButton
          class="btn btn--primary-cta flex-100 w-full md:ml-2"
          :id="this.$options.name + '-purchase'"
          :disabled="!valid"
          :class="{ disabled: !valid }"
          :loading="!purchaseLoaded"
          :button-text="buttonText"
          v-on:click="purchase"></MyButton>
      </div>
    </div>

    <Modal :modalName="'terms_card'">
      <div slot="header">
        <h2>{{ t('CheckoutV2', 'terms_card_header') }}</h2>
      </div>
      <div slot="body">
        <div v-html="t('CheckoutV2', 'terms_card_text')"></div>
      </div>
    </Modal>

    <Modal :modalName="'terms_invoice'">
      <div slot="header">
        <h2>{{ t('CheckoutV2', 'terms_invoice_header') }}</h2>
      </div>
      <div slot="body">
        <div v-html="t('CheckoutV2', 'terms_invoice_text')"></div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { imageUrl, translate } from '../store/helpers';
import MyButton from './Button';
import PreviousExtension from "./extension-steps/PreviousExtension";
import LoadingView from './LoadingView';
import Modal from './Modal';
import { gtm } from '../utils/gtm';

export default {
  name: 'checkout-component',
  components: { LoadingView, MyButton, Modal, PreviousExtension },
  data() {
    return {
      paymentType: 'recurring',
      selectedPaymentOption: '',
      acceptedTerms: false,
      acceptedCardTerms: false,
      acceptedInvoiceTerms: false,
      paymentOptions: [],
      paymentOptionsLoaded: false,
      purchaseLoaded: true,
      action: '',
      password: '',
      validPassword: true,
      showErrorMessage: false,
      shouldValidate: false,
      genericError: false,
    };
  },
  props: {
    campaign: {
      type: Object,
      default: () => {}
    },
    offer: {
      type: Object,
      default: () => {}
    },
    activePaymentMethod: {
      type: Object,
      default: () => {}
    },
    showTerms: {
      type: Boolean,
      default: false
    },
    showPassword: {
      type: Boolean,
      default: false
    },
    checkoutEndpointAction: {
      type: String,
      default: 'order_extension'
    },
    termsText: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.fetchPaymentOptions();

    if (!this.shouldShowRecurringPaymentMethod) {
      this.paymentType = 'card';
    }
  },
  methods: {
    fetchPaymentOptions() {
      window.axios
        .get('/wp/wp-admin/admin-ajax.php', {
          params: {
            campaign_id: this.campaign.external_id,
            action: 'get_payment_options'
          }
        })
        .then(response => {
          this.paymentOptions = response.data;
          this.paymentOptionsLoaded = true;
        });
    },
    purchase() {
      this.shouldValidate = true;
      if (!this.valid) {
        if (
          !this.acceptedTerms ||
          (this.cardsTermsCheckbox && !this.acceptedCardTerms)
        ) {
          this.showErrorMessage = true;
        } else {
          this.showErrorMessage = false;
        }
        return;
      }
      this.showErrorMessage = false;
      this.purchaseLoaded = false;
      const data = new FormData();
      data.append('campaign_id', this.campaign.external_id);
      data.append('offer_id', this.offer.id);
      data.append('payment_option', this.selectedPaymentOption);

      if (this.activePaymentMethodIsRecurring) {
        data.append('payment_option_type', this.activePaymentMethod.paymentTypeApi === 'mobilePayment' ? 'MPS' : 'WEBPAY');
      }

      if (this.currentUrl) {
        data.append('cancel_url', this.currentUrl);
      }

      if (this.campaign.is_digital_subscriber_campaign) {
        data.append('password', this.password);
      }
      data.append('checkout_page_url', window.location.href);
      data.append('is_test', this.isTest);
      if (this.testZip.length) {
        data.append('test_zip', this.testZip);
      }
      gtm.clickPurchaseButton();
      gtm.pushGoToPayment([this.offer.newDataLayer])

      window.axios
        .post(
          `/wp/wp-admin/admin-ajax.php?action=${this.checkoutEndpointAction}`,
          data
        )
        .then(response => {
          if (typeof response.data.url === 'undefined') {
            this.purchaseLoaded = true;
            this.genericError = true;
            this.showErrorMessage = true;
            return;
          }

          this.action = response.data.url;
          this.purchaseLoaded = true;
          // Redirect to payment
          window.location.href = this.action;
        });
    },
    openTerms() {
      this.open('terms');
    },
    openCardTerms() {
      this.open('terms_card');
    },
    openInvoiceTerms() {
      this.open('terms_invoice');
    },
    t(component, key) {
      return translate(component, key);
    },
    ...mapMutations({
      open: 'modalModule/open'
    }),
    paymentTypeMap() {
      switch(this.paymentType) {
        case 'card':
          return 'cardPayment';
        case 'recurring':
          return 'Recurring';
        case 'invoice':
          return 'Invoice';
      }
    }
  },
  watch: {
    paymentOptionsLoaded(newValue) {
      if (newValue && this.paymentOptions[0]?.id === 'recurring') {
        gtm.selectPaymentOption(this.activePaymentMethod.paymentTypeApi, true);
        this.selectedPaymentOption = this.paymentOptions[0].value;
      }
      else if (this.hasOnlyOneCardPaymentOption) {
        this.selectedPaymentOption = this.cardPaymentOption.value;
      }
    },
    selectedPaymentOption(newValue, oldValue) {
      gtm.selectPaymentOption(this.paymentTypeMap(), oldValue === '');
      gtm.pushAddPaymentInfo([this.offer.newDataLayer])
    },
    paymentType(newValue) {
      this.showErrorMessage = false;
      gtm.selectPaymentOption(this.paymentTypeMap());

      if (newValue === 'card' && this.hasOnlyOneCardPaymentOption) {
        this.selectedPaymentOption = this.cardPaymentOption.value;
      } else if (newValue === 'invoice' && this.hasGiroPayment) {
        this.selectedPaymentOption = this.giroPaymentOption.value;
      } else {
        this.selectedPaymentOption = '';
      }

      if (newValue !== 'card') {
        this.acceptedCardTerms = false;
      }
      if (newValue !== 'invoice') {
        this.acceptedInvoiceTerms = false;
      }
    },
    acceptedTerms() {
      this.showErrorMessage = this.cardsTermsCheckbox
        ? !this.acceptedCardTerms
        : false;
    },
    acceptedCardTerms() {
      this.showErrorMessage = false;
    }
  },
  computed: {
    activePaymentMethodIsRecurring() {
      return (this?.activePaymentMethod?.recurring &&  this.activePaymentMethod?.paymentTypeApi !== 'mobilePayment') ?? false
    },
    activePaymentMethodIsInvoice() {
      return this?.activePaymentMethod?.paymentTypeApi === 'invoice';
    },
    shouldShowRecurringPaymentMethod() {
      return this.activePaymentMethodIsRecurring &&
        (
          this.hasCardNumber &&
          this.activePaymentMethod?.paymentTypeApi === 'cardPayment'
        );
    },
    hasCardNumber() {
      return this.activePaymentMethod && this.activePaymentMethod?.cardNumber !== undefined && this.activePaymentMethod?.cardNumber.length > 0;
    },
    hasActivePaymentMethod() {
      return typeof this.activePaymentMethod === 'object' && Object.keys(this.activePaymentMethod).length !== 0;
    },
    selectedPaymentOptionNicename() {
      const selectedPaymentOption = this.paymentOptions.find(
        po => po.value === this.selectedPaymentOption
      );
      return selectedPaymentOption ? selectedPaymentOption.label : 'n/a';
    },
    debitImage() {
      return imageUrl('logo-cards-debit.png');
    },
    dankortImage() {
      return imageUrl('logo-cards-dankort.png');
    },
    giroPaymentOption() {
      return this.paymentOptions.filter(
        paymentOption => paymentOption.isGiro
      )[0] ?? false;
    },
    cardPaymentOption() {
      return this.paymentOptions.filter(
        paymentOption => paymentOption.isCard
      )[0];
    },
    selectedPaymentIsMobilePay() {
      return (
        this.selectedPaymentOption?.toLowerCase().indexOf('mobilepay') !== -1
      );
    },
    selectedPaymentIsCard() {
      return (
        this.selectedPaymentOption?.toLowerCase().indexOf('webpay') !== -1
      );
    },
    pricePerIssueText() {
      if (
        this.campaign.is_digital_subscriber_campaign ||
        this.campaign.is_wype
      ) {
        return translate('CheckoutV2', 'price_per_month');
      }
      return translate('CheckoutV2', 'price_per_number');
    },
    buttonText() {
      return this.paymentType === 'invoice' || this.activePaymentMethodIsRecurring
        ? translate('CheckoutV2', 'accept')
        : translate('CheckoutV2', 'toSecurePayment');
    },
    valid() {
      if (this.showPassword && this.password.length < 6) {
        return false;
      }
      if (this.shouldShowRecurringPaymentMethod) {
        return true;
      }
      if (this.showTerms && !this.acceptedTerms) {
        return false;
      }
      if (this.cardsTermsCheckbox && !this.acceptedCardTerms && this.paymentType === 'card') {
        return false;
      }
      if (!this.acceptedInvoiceTerms && this.paymentType === 'invoice' && !this.activePaymentMethodIsInvoice) {
        return false;
      }
      if (this.selectedPaymentOption.length === 0) {
        return false;
      }
      return true;
    },
    cardsTermsCheckbox() {
      return !this.selectedPaymentIsMobilePay && !this.selectedPaymentIsCard;
    },
    invoiceTermsCheckbox() {
      return !this.activePaymentMethodIsInvoice;
    },
    hasOnlyOneCardPaymentOption() {
      return (
        this.paymentOptions.filter(paymentOption => paymentOption.isCard)
          .length === 1
      );
    },
    hasGiroPayment() {
      return (
        this.paymentOptions.filter(paymentOption => paymentOption.isGiro)
          .length > 0
      );
    },
    ...mapGetters(['primaryColor', 'market', 'isTest', 'testZip', 'currentUrl'])
  }
};
</script>

<style scoped lang="scss">
</style>
